import { APP_URL } from "../helpers/constants";

function sendPrescription(data) {
  const url = `${APP_URL}/url-router/routes/public/prescriptions/`;
  return fetch(url, {
    mode: "cors",
    credentials: "include",
    method: "POST",
    body: data
  }).then(res => res);
}

export { sendPrescription };
