import React, { useRef, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "mpharma-components-library/lib/Button";
import TextInput from "mpharma-components-library/lib/TextInput";
import ReactFlagsSelect from "react-flags-select";
import "react-flags-select/css/react-flags-select.css";
import { ReactComponent as MuttiLogo } from "../../images/Mutti.svg";
import { ReactComponent as HaltonsLogo } from "../../images/HaltonsLogo.svg";
import Upload from "../Upload";
import TextArea from "mpharma-components-library/lib/TextArea";

function Form({
  partner,
  values,
  handleChange,
  errors,
  handleSelectFlag,
  getRootProps,
  getInputProps,
  open,
  thumbs,
  disablePrescriptionButton,
  sendPrescription,
  loadingButtonState,
  fileUploadErrorMessages,
  isCompressing,
  files
}) {
  let countryRef = useRef();
  const countries = partner === "haltons" ? ["KE"] : ["GH", "KE", "NG", "ZM"];
  const customLabels =
    partner === "haltons"
      ? { KE: "Kenya (+254)" }
      : {
          GH: "Ghana (+233)",
          KE: "Kenya (+254)",
          NG: "Nigeria (+234)",
          ZM: "Zambia (+260)"
        };
  const defaultCountry = partner === "haltons" ? "KE" : "";

  useEffect(() => {
    // initialize default country code
    defaultCountry && handleSelectFlag(defaultCountry);
    //eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      {partner === "haltons" && (
        <div style={headingContainer}>
          <HaltonsLogo />
          <h2 style={sub}>Haltons electronic prescription uploader</h2>
        </div>
      )}
      {partner !== "haltons" && (
        <div style={headingContainer}>
          <MuttiLogo />
          <h2 style={sub}>Mutti electronic prescription uploader</h2>
        </div>
      )}

      {partner === "physician" && (
        <Fragment>
          <div style={errorMessageAlign}>
            <span style={labelText}>
              Prescriber's full name{" "}
              <span style={labelRequiredText}>(required)</span>
            </span>
            <TextInput
              type="text"
              name="prescriberFullName"
              placeholder="eg. John Doe"
              fullWidth
              error={errors.prescriberFullName}
              errorMessage={errors.prescriberFullNameErrorMessage}
              onChange={handleChange}
              value={values.prescriberFullName || ""}
              data-testid="prescriberFullName"
              style={fieldHeight}
            />
          </div>

          <div style={errorMessageAlign}>
            <span style={labelText}>
              Prescriber's facility name{" "}
              <span style={labelRequiredText}>(required)</span>
            </span>
            <TextInput
              type="text"
              name="prescriberFacilityName"
              fullWidth
              error={errors.prescriberFacilityName}
              errorMessage={errors.prescriberFacilityNameErrorMessage}
              onChange={handleChange}
              value={values.prescriberFacilityName || ""}
              data-testid="prescriberFacilityName"
              style={fieldHeight}
            />
          </div>
        </Fragment>
      )}

      <div style={errorMessageAlign}>
        {partner === "physician" && (
          <span style={labelText}>
            Patient's first name{" "}
            <span style={labelRequiredText}>(required)</span>
          </span>
        )}
        {partner !== "physician" && (
          <span style={labelText}>
            First name <span style={labelRequiredText}>(required)</span>
          </span>
        )}
        <TextInput
          type="text"
          name="firstName"
          placeholder="eg. John"
          fullWidth
          error={errors.firstName}
          errorMessage={errors.firstNameErrorMessage}
          onChange={handleChange}
          value={values.firstName || ""}
          data-testid="firstName"
          style={fieldHeight}
        />
      </div>

      <div style={errorMessageAlign}>
        {partner === "physician" && (
          <span style={labelText}>
            Patient's last name{" "}
            <span style={labelRequiredText}>(required)</span>
          </span>
        )}
        {partner !== "physician" && (
          <span style={labelText}>
            Last name <span style={labelRequiredText}>(required)</span>
          </span>
        )}
        <TextInput
          type="text"
          name="lastName"
          placeholder="eg. Doe"
          fullWidth
          error={errors.lastName}
          errorMessage={errors.lastNameErrorMessage}
          onChange={handleChange}
          value={values.lastName || ""}
          data-testid="lastName"
          style={fieldHeight}
        />
      </div>

      <div style={errorMessageAlign}>
        <label htmlFor="phoneNumber">
          {partner === "physician" && (
            <span style={labelText}>Patient's phone number</span>
          )}
          {partner !== "physician" && (
            <span style={labelText}>
              Phone number
              <span style={labelRequiredText}> (required)</span>
            </span>
          )}
        </label>
        <TextInput
          id="phoneNumber"
          type="text"
          data-testid="phoneNumber"
          onChange={handleChange}
          name="phoneNumber"
          error={errors.phoneNumber}
          errorMessage={errors.phoneNumberErrorMessage}
          value={values.phoneNumber || ""}
          style={{ paddingLeft: 190, height: 50 }}
          fullWidth
          currency={
            <ReactFlagsSelect
              placeholder="Country Code"
              countries={countries}
              customLabels={customLabels}
              defaultCountry={defaultCountry}
              onSelect={handleSelectFlag}
              name="mobileCode"
              ref={countryRef}
              data-testid="mobileCode"
            />
          }
        />
      </div>

      {partner === "haltons" && (
        <div style={errorMessageAlign}>
          <Fragment>
            <label style={labelText} htmlFor="nationalID">
              National ID (optional)
            </label>
            <TextInput
              type="number"
              name="nationalID"
              placeholder="8-digit number"
              min="0"
              fullWidth
              error={errors.nationalID}
              errorMessage={errors.nationalIDErrorMessage}
              onChange={handleChange}
              value={values.nationalID || ""}
              data-testid="nationalID"
              style={fieldHeight}
            />
          </Fragment>
        </div>
      )}

      {partner !== "haltons" && partner !== "physician" && (
        <div style={errorMessageAlign}>
          <Fragment>
            <label style={labelText} htmlFor="muttiID">
              Mutti ID (optional)
            </label>
            <TextInput
              id="muttiID"
              type="number"
              name="muttiID"
              placeholder="7-digit number behind mutti card"
              min="0"
              fullWidth
              error={errors.muttiID}
              errorMessage={errors.muttiIDErrorMessage}
              onChange={handleChange}
              value={values.muttiID || ""}
              data-testid="muttiID"
              style={fieldHeight}
            />
          </Fragment>
        </div>
      )}

      {partner !== "physician" && (
        <div>
          <span style={labelText}>
            Upload your prescription{" "}
            <span style={labelRequiredText}>(required)</span>
          </span>
          <Upload
            getRootProps={getRootProps}
            open={open}
            getInputProps={getInputProps}
          />
          <div style={{ marginBottom: "1rem", textAlign: "left" }}>
            {fileUploadErrorMessages && fileUploadErrorMessages.FileCount && (
              <span style={errorText}>{fileUploadErrorMessages.FileCount}</span>
            )}

            {isCompressing && (
              <span style={{ ...errorText, textAlign: "center" }}>
                Please wait, your prescription is uploading
              </span>
            )}

            {!fileUploadErrorMessages && files.length > 0 && (
              <aside style={thumbsContainer}>{thumbs}</aside>
            )}
          </div>
        </div>
      )}

      {partner === "physician" && (
        <div style={errorMessageAlign}>
          <span style={labelText}>
            Patient's prescription{" "}
            <span style={labelRequiredText}>(required)</span>
          </span>
          <TextArea
            name="prescriptionText"
            placeholder="Type patient’s prescription here..."
            error={errors.prescriptionText}
            errorMessage={errors.prescriptionTextErrorMessage}
            onChange={handleChange}
            value={values.prescriptionText || ""}
            data-testid="prescriptionText"
          />
          {errors.prescriptionText && (
            <span style={prescriptionTextError}>
              {errors.prescriptionTextErrorMessage}
            </span>
          )}
        </div>
      )}

      <Button
        onClick={sendPrescription}
        secondary={loadingButtonState ? true : false}
        size="lg"
        text={loadingButtonState ? "Loading..." : "Send prescription"}
        disabled={disablePrescriptionButton()}
      />
    </Fragment>
  );
}

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16
};

const errorText = {
  display: "block",
  color: "#FF647C",
  fontSize: "1.4rem"
};

const labelText = {
  color: "#6D7278",
  fontSize: "1.4rem",
  textAlign: "left",
  display: "block",
  marginBottom: "1.5rem"
};

const labelRequiredText = {
  color: "#FF647C"
};

const fieldHeight = {
  height: "50px"
};

const headingContainer = {
  textAlign: "center",
  marginBottom: "4rem",
  letterSpacing: ".1rem"
};

const sub = {
  color: "#000"
};

const errorMessageAlign = {
  textAlign: "left"
};

const prescriptionTextError = {
  ...errorText,
  marginTop: -25,
  marginBottom: 25
};

Form.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  errors: PropTypes.object,
  handleSelectFlag: PropTypes.func,
  getRootProps: PropTypes.func,
  getInputProps: PropTypes.func,
  open: PropTypes.func,
  thumbs: PropTypes.array,
  disablePrescriptionButton: PropTypes.func,
  sendPrescription: PropTypes.func,
  loadingButtonState: PropTypes.bool,
  fileUploadErrorMessage: PropTypes.string,
  isCompressing: PropTypes.bool,
  files: PropTypes.array
};

export default Form;
