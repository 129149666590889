import React, { useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Prescription from "./components/Prescription";
import Home from "./components/Home";
import { DATACORAL_API_KEY, DATACORAL_ENV } from "./helpers/constants";

const AppRouter = props => {
  useEffect(() => {
    // Initialize Datacoral Snowplow Tracker
    window.datacoral &&
      window.datacoral(
        "newTracker",
        "prescriptions",
        "events.mpharma.datacoral.io",
        {
          appId: "prescriptions",
          platform: "desktop",
          apiKey: DATACORAL_API_KEY, // THIS IS ENV DEPENDENT
          datacoralEnv: DATACORAL_ENV, // THIS IS ENV DEPENDENT
          forceSecureTracker: true,
          post: true
        }
      );

    window.datacoral("enableActivityTracking", 30, 30);
    window.datacoral("trackPageView");
  }, []);
  return (
    <Router>
      <Route path="/" exact component={Home} />
      <Route path="/send" exact component={Prescription} />
      <Route path="/haltons" exact component={Prescription} />
      <Route path="/physician" exact component={Prescription} />
    </Router>
  );
};

export default AppRouter;
